.container-btn{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    align-items: center;
    @media screen and (min-width : $mdScreen){
        width: 50%;
        flex-direction: row;
    }

    .btn-gradient-cta{
        display: flex;
        justify-content: center;
        align-items: center;
    
        width: 250px;
        min-height: 76px;
        margin: 20px auto;
        font-size: 24px;
        letter-spacing: 6px;
        font-weight: $ftBold;
        text-align: center;
        text-decoration: unset;
    
        color: #fff;
        background: linear-gradient(90deg, $blue, $red);
    
        border-radius: 8px;
        border: solid 1px lightgray;
        transition: all 0.3s linear;
        &:hover{
            -webkit-box-shadow: 0px 5px 30px -10px rgba(13,27,42,1);
            -moz-box-shadow: 0px 5px 30px -10px rgba(13,27,42,1);
            box-shadow: 0px 5px 30px -10px rgba(13,27,42,1);
        }
    }
}

.btnSource{
    box-sizing: border-box;
    color: #fff;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: $ftBold;
    text-decoration: unset;
    padding: 5px 10px;
    margin: 10px 2px;
    border: solid 1px $purple;
    border-radius: 8px;
    background: linear-gradient(90deg, $blue, $red);
    transition: all 0.3s linear;
    &:hover{
        -webkit-box-shadow: 0px 5px 30px -10px rgba(13,27,42,1);
        -moz-box-shadow: 0px 5px 30px -10px rgba(13,27,42,1);
        box-shadow: 0px 5px 30px -10px rgba(13,27,42,1);
    }
}

.btnProd{
    display: flex;
    align-items: center;
    color: $blue;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: $ftBold;
    text-decoration: unset;
    padding: 5px 10px;
    border: solid 1px $blue;
    border-radius: 8px;
    margin: 10px 2px;

    transition: all 0.3s linear;
    &:hover{
        color: #fff;
        background-color: $blue;
    }
}