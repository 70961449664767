#about{
    position: relative;
    padding: 20px;
    margin: 0 30px 0;
    @media screen and (min-width : $mdScreen){
        margin: 0 60px 0;
    }
    h2{
        text-align: center;
        font-weight: $ftBold;
        font-size: 52px;
        color :  $blue;
        @media screen and (min-width : $mdScreen){
            padding: 40px 10px;
        }
    }
    .part{
        margin: 25px auto;
        @media screen and (min-width : $mdScreen){
            margin: 150px auto;
        }
        h3{
            position: relative;
            font-weight: $ftLight;
            font-size: 19px;
            letter-spacing: 5px;
            padding: 4px;
            overflow-wrap: break-word;
            margin-bottom: 50px;
            @media screen and (min-width : $lgScreen){
                width: max-content;
                text-align: center;
                padding: 10px;
                font-size: 48px;
                margin-bottom: 50px;
                letter-spacing: 10px;
            }
            &::before{
                font-size: 40px;
                letter-spacing: 5px;
                font-weight: $ftBold;
                @media screen and (min-width : $lgScreen){
                    font-size: 100px;
                    letter-spacing: 10px;
                    position: absolute;
                    margin-right: 20px;
                    left: -130px;
                    top: -25px;
                }

            }
        }

        &.one{
            h3{
                @media screen and (min-width : $mdScreen){
                    margin-left: 120px;
                }
                &::before{
                    content: "01";
                    color: $red;
                }
            }
            p{
                font-size: 24px;
                padding: 10px;
                margin-bottom: 15px;
                line-height: 60px;
                @media screen and (min-width : $mdScreen){
                    width: 75%;
                    margin-bottom: 35px;
                    font-size: 30px;
                }
            }
        }
        &.two{
            h3{
                margin-left: auto;
                &::before{
                    content: "02";
                    color: $purple;
                }
            }

            .cardContainer{
                
                justify-content: space-around;
                margin: 40px auto;
                @media screen and (min-width : $lgScreen){
                    display: flex;
                }
                .card{
                    background-color: #fff;
                    margin: 40px auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    min-height: 300px;
                    max-width: 300px;
                    width: 100%;
                    padding: 20px;
                    text-align: center;
                    border-radius: 8px;
                    border: 1px solid $blue;

                    transition: box-shadow linear 0.3s;
                    background: $bgColor;
                    &:hover{
                        box-shadow:  20px 20px 60px #c8cfd5, 
                        -20px -20px 60px #ffffff;
                        transform: translateY(-4px);
                    }

                    @media screen and (min-width : $lgScreen){
                        margin: auto;
                    }
                    img{
                        max-width: 110px;
                        margin: 0 auto 20px;
                    }
                    h4{
                        margin-bottom: 15px;
                        font-size: 24px;
                        letter-spacing: 4px;
                    }
                    p{
                        margin-bottom: 10px;
                        letter-spacing: 2px;
                        font-weight: $ftLight;
                    }
                }
            }

        }
        &.three{
            h3{
                @media screen and (min-width : $mdScreen){
                    margin-left: 120px;
                }
                &::before{
                    content: "03";
                    color: $blue;
                }
            }
            h4{
                text-align: center;
                font-size: 32px;
                font-weight: $ftLight;
            }
            .cardContainer{
                display: flex;
                margin: 40px auto;
                align-items: center;
                justify-content: space-evenly;
                flex-wrap: wrap;
                @media screen and (min-width: $mdScreen){
                    margin: 80px auto;
                }
                .card-tech{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    border: 1px solid $purple;
                    border-radius: 8px;
                    max-width: 130px;
                    width: 100%;
                    height: 150px;
                    padding: 20px;
                    margin: 10px auto;
                    transition: all linear 0.3s;
                    background: $bgColor;
                    &:hover{
                        box-shadow:  20px 20px 60px #c8cfd5, 
                        -20px -20px 60px #ffffff;
                        transform: translateY(-4px);
                    }

                    @media screen and (min-width: $mdScreen){
                        max-width: 130px;
                        height: 130px;
                    }                    
                    
                    img{
                        max-width: 50px;
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    p{
                        font-weight: $ftBold;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}