$lgScreen : 1200px;
$mdScreen : 768px;
$smScreen : 576px;

@import './configs/colors.scss';
@import './configs/font.scss';
@import './configs/buttons.scss';

@import './components/hero.scss';
@import './components/rs.scss';
@import './components/about.scss';
@import './components//projects.scss';
@import './components/footer.scss';



*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
}

section{
    min-height: 95vh;
    background-color: $bgColor;
    overflow-x: hidden;
}

.container{
    max-width: 1180px;
    margin: auto;
}