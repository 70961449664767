#hero{
    margin: 30px 30px 0;
    position: relative;
    @media screen and (min-width : $mdScreen){
        margin: 60px 60px 0;
    }
    .hero-content{
        position: absolute;
        width: 100%;
        top: 15%;
        left: 0;
        right: 0;
        z-index: 10;
        h1 {
            font-size: 40px;
            margin-bottom: 20px;
            text-align: center;
            letter-spacing: 10px;
            color: $red;
            opacity: 0.75;
            font-weight: $ftBold;
        }
        h2{
            text-align: center;
            font-size: 30px;
            letter-spacing: 6px;
            margin-bottom: 30px;
            color:  $blue;
            font-weight: $ftBold;
            opacity: 0.8;
        }
        p{
            font-size: 20px;
            padding: 10px;
            margin-bottom: 60px;
            color: $red;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 4px;
            font-weight: $ftLight;
            opacity: 0.6;
            span{
                color: $black;
                font-weight: $ftBold;
                @media screen and (min-width: $mdScreen){
                    color: $blue;                    
                }

            }
        }
        @media screen and (min-width : $mdScreen){
            top: 25%;
            h1 {
                font-size: 70px;
                color: $purple;
                margin-bottom: 40px;
            }
            h2{
                font-size: 48px;
                margin-bottom: 60px;
            }
            p{
                font-size: 24px;
                margin-bottom: 80px;
            }
        }
    }
    #topBlob{
        position: absolute;
        top: -250px;
        right: 0;
        @media screen and (min-width : $mdScreen){
            top: -40px;
        }
    }
    
    #bottomBlob{
        position: absolute;
        bottom: 0;
        left: 0;
        @media screen and (min-width : $mdScreen){
            bottom: 0px;
        }
    }
}