#projects{
    position: relative;
    padding: 20px;
    margin: 0 30px 0;
    @media screen and (min-width : $mdScreen){
        margin: 0 60px 0;
    }
    h2{
        text-align: center;
        font-weight: $ftBold;
        font-size: 52px;
        letter-spacing: 6px;
        color :  $blue;
        @media screen and (min-width : $mdScreen){
            padding: 40px 10px;
        }
    }

    .cardProjectContainer{   
        justify-content: space-around;
        margin: 40px auto;
        flex-wrap: wrap;
        @media screen and (min-width : $lgScreen){
            display: flex;
        }
        .card{
            margin: 40px auto;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            min-height: 300px;
            max-width: 300px;
            width: 100%;
            
            padding: 20px;
            text-align: center;
            border-radius: 8px;
            border: 1px solid $purple;
            background-color: #fff;

            transition: box-shadow linear 0.3s;
            background: $bgColor;
            &:hover{
                box-shadow:  20px 20px 60px #c8cfd5, 
                -20px -20px 60px #ffffff;
                transform: translateY(-4px);
            }
            
            @media screen and (min-width : $lgScreen){
                margin: 20px auto;
            }
            img{
                @media screen and (min-width : $mdScreen) {
                    margin: 0 auto 20px;
                }
            }
            h4{
                margin-bottom: 15px;
                font-size: 24px;
                letter-spacing: 4px;
            }
            p{
                margin-bottom: 10px;
                letter-spacing: 2px;
                font-weight: $ftLight;
            }
            .btnContainerProject{
                margin: 20px auto 0;
                display: flex;
                justify-content: center;
            }
        }
    }

}