#rs{
    position: fixed;
    top: 35%;
    left: 30px;
    z-index: 10;
    display: block;
    flex-direction: column;
    @media screen and (min-width : $smScreen){
        left: 80px;        
    }
    a{
        display: flex;
        margin: 20px 0;
        align-items: center;
        text-decoration: unset;
        color: black;
        &:hover{
            span{
                display: block;
            }
        }
        img{
            width: 42px;
            margin-right: 10px;
        }
        span{
            display: none;
            font-weight: $ftBold;
        }
    }
}